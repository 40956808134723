/* Base */

#awwwards {
	position: absolute;
	top: 20px;
	width: 90px;
	height: 135px;
	text-indent: -666em;
	overflow: hidden;
	z-index: 999;

	-webkit-transition: all 1s ease;
    transition: all 1s ease; 
}
#awwwards.top {
	top: 20px;
}
#awwwards.left {
	left: 0;
}
#awwwards.right {
	right: 0;
}

.work.domitor .project-hero #awwwards {
	top: -5px;
}

#awwwards a {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 90px;
	height: 135px;
	background-repeat: no-repeat;
	background-size: 90px 135px;
}

/* NOMINEE */


#awwwards.nominee.green.left a {
	background-image: url(../img/awwwards_nominee_green_left.png);
}


@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2),	only screen and (min-device-pixel-ratio: 1.5) {

	/* NOMINEE */

	#awwwards.nominee.green.left a{
		background-image: url(../img/awwwards_nominee_green_left@2x.png);
	}


}